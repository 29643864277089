.noScrollbarWithDescendants {
  &,
  * {
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.noScrollbar {
  &,
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.customScrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--palette-white);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--palette-gray-200);
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--palette-gray-300);
  }
}
